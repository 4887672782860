:root {
  --main-grid-template-columns: 1fr 4fr 1fr;
  --small-grid-template-columns: 0.5fr 4fr 0.5fr;
  --laptop-grid-template-columns: 1fr 7fr 1fr;
  --wide-grid-template-columns: 2fr 3fr 2fr;
}

body {
  height: 100vh;
  margin: 0;
  font-family: "Gilroy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root, #root > div {
    height: 100%;
  }

  @media only screen and (max-width: 480px) {
    height: 100dvh;
    --main-grid-template-columns: var(--small-grid-template-columns);
  }

  @media (min-width: 1280px) {
    --main-grid-template-columns: 1fr 4fr 1fr;
  }

  @media (min-width: 1024px) {
    --main-grid-template-columns: 0.5fr 10fr 0.5fr;
  }

  @media (min-width: 1366px) {
    --main-grid-template-columns: 1fr 8fr 1fr;
  }

  @media (min-width: 1536px) {
    --main-grid-template-columns: 1fr 6fr 1fr;
  }

  @media screen and (min-width: 1900px) {
    --main-grid-template-columns: 1fr 4fr 1fr;
  }

  @media screen and (min-width: 3840px) {
    --main-grid-template-columns: 4fr 4fr 4fr;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Gilroy";
  font-display: fallback;
  src: url("assets/fonts/Gilroy/Gilroy-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: "Gilroy";
  font-display: fallback;
  src: url("assets/fonts/Gilroy/Gilroy-Medium.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: "Gilroy";
  font-display: fallback;
  src: url("assets/fonts/Gilroy/Gilroy-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  font-display: fallback;
  src: url("assets/fonts/Gilroy/Gilroy-Bold.ttf") format('truetype');
  font-weight: 700;
}