.location {
  grid-area: location;
  background-size: cover;
  height: 648px;
  background-image: url("../../assets/location-back.png");
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". location-wrapper .";

  @media only screen and (max-width: 480px) {
    background-image: url("../../assets/location-back-small.png");
    // grid-template-columns: var(--small-grid-template-columns);
  }

  &-wrapper {
    grid-area: location-wrapper;
    display: flex;
    gap: 30px;
    justify-content: space-between;

    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
      padding: 24px 20px;
    }
  }

  &__info {
    max-width: 414px;
    border-radius: 20px;
    background: #ffffff;
    padding: 45px 28px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (max-width: 480px) {
      padding: 24px 28px 24px 19px;
    }

    &-title {
      color: #596dbb;
      font-size: clamp(36px, 3vw, 50px);
      font-weight: 700;
    }

    &-subtitle {
      color: #596dbb;
      font-size: clamp(24px, 3vw, 32px);
      font-weight: 700;
    }

    &-address {
      color: #000000;
      font-size: clamp(18px, 3vw, 24px);
      font-weight: 400;
    }
  }

  &__map {
    display: inline-block;

    @media only screen and (max-width: 480px) {
      width: 100%;
      iframe {
        width: 100%;
      }
    }
  }
}
