.report-card {
  width: clamp(270px, 20vw, 320px);
  height: 305px;
  background: #f6f7fb;
  border-radius: 20px;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background: #f0f3fc;
    .report-card__title {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &.short {
    height: 80px;
    width: calc(100% - 32px);
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
  }

  &__title {
    color: #596dbb;
    font-weight: 700;
    font-size: 18px;
  }

  &__reporter {
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 13px 8px;
  }

  &.empty {
    text-decoration: none;
    .title {
      color: #596dbb;
      font-weight: 700;
      font-size: 24px;
    }
    &:hover {
      .report-card__title {
        text-decoration: underline;
      }
    }

    .report-card__title {
      text-decoration: none;
    }

    .button {
      align-self: center;
      width: 100%;

      button {
        padding: 8px 16px;
        gap: 10px;
        width: 100%;
        height: 36px;
        border-radius: 54px;
        text-transform: unset;
        background: #f38701;
        border: 1px solid #f38701;
        color: #ffffff;
        font-weight: 700;
        font-family: "Gilroy";
        font-size: 16px;
      }
    }
  }
}

.reporter {
  display: flex;
  gap: 16px;
  align-items: center;

  &-avatar {
    .MuiAvatar-root {
      border: 2px solid #f38701;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__name {
      font-weight: 500;
      font-size: 16px;
      color: #0f172a;
    }

    &__company {
      font-weight: 400;
      font-size: 14px;
      color: #6a737c;
    }
  }
}
