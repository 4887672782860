.footer {
  grid-area: footer;
  background: #596dbb;
  padding: 30px 0;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". footer-wrapper .";
  display: grid;
  height: 140px;

  @media only screen and (max-width: 480px) {
    grid-template-areas: "footer-wrapper footer-wrapper .";
    // grid-template-columns: var(--small-grid-template-columns);
    padding: 40px 20px;
    height: auto;
  }

  &-wrapper {
    grid-area: footer-wrapper;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 50px;
    flex-wrap: wrap;
    @media only screen and (max-width: 480px) {
      justify-content: space-between;
    }
  }

  &__logo {
    &-logo {
      margin-bottom: 15px;
    }

    &-subtitle {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__contacts {
    &-title {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 34px;
    }

    &-email {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    &-socials {
      display: flex;
      gap: 24px;
      margin-top: 15px;
    }
  }

  &__rights {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    align-self: flex-end;
  }
}
