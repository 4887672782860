.partners {
  grid-area: partners;
  background: #f6f7fb;
  padding: 80px 0;
  display: grid;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". partners-wrapper .";

  @media only screen and (max-width: 480px) {
    padding: 300px 0 80px 0;
    // grid-template-columns: var(--small-grid-template-columns);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    grid-area: partners-wrapper;
  }

  &__title {
    font-size: clamp(32px, 3vw, 50px);
    font-weight: 700;
    color: #596dbb;
  }

  &__logo {
    border-radius: 20px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    width: 405px;
    height: 150px;
    align-items: center;

    @media only screen and (max-width: 480px) {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 30px;
    justify-content: center;
  }

  &__icon {
    width: 100%;
    height: 100%;
  }

  &__link {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
  }

  &__link--pvs {
    width: 100%;
    height: 100%;
    padding: 4px 40px;
    box-sizing: border-box;
  }
}
