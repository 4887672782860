.details {
  margin-top: 80px;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". details-wrapper .";
  display: grid;
  min-height: calc(100% - 200px - 160px);
  background: #f6f7fb;
  // min-height: 480px;

  @media only screen and (max-width: 480px) {
    height: auto;
  }

  &-wrapper {
    grid-area: details-wrapper;
    display: flex;
    gap: 30px;
    margin-bottom: 30px;

    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &-place {
      display: flex;
      flex-direction: row;
      gap: 8px;
      font-family: "Gilroy";

      &__hall, &__time {
        padding: 6px 8px;
        border-radius: 6px;
        font-size: 14px;
      }

      &__hall {
        font-weight: 700;
        color: white;
        background-color: #F38701;
      }

      &__time {
        background-color: #FFE8D2;
      }
    }

    &-title {
      color: #596dbb;
      font-size: clamp(24px, 3vw, 32px);
      font-weight: 700;
    }

    &-description {
      color: #000000;
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 400;
      line-height: 150%;
      white-space: pre-wrap;
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 480px) {
        flex-direction: column;
        gap: 24px;
      }
    }

    &-tags {
      display: inline-flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    &-back {
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        border: 2px solid #596dbb;
        border-radius: 54px;
        font-family: "Gilroy";
        font-weight: 600;
        font-size: 16px;
        color: #596dbb;
      }
    }
  }

  &__reporter {
    max-width: 170px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media only screen and (max-width: 480px) {
      flex-direction: row;
    }

    .MuiAvatar-root {
      border: 2px solid #f38701;
    }

    &-name {
      font-size: clamp(18px, 3vw, 24px);
      font-weight: 500;
      color: #0f172a;
      margin-bottom: 10px;
    }

    &-position {
      color: #6a737c;
      font-size: clamp(14px, 3vw, 16px);
      font-weight: 400;
    }
  }
}
