.menu {
  width: calc(100%);
  height: 80px;
  position: fixed;
  display: grid;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". menu-wrapper .";
  z-index: 9;

  @media only screen and (max-width: 480px) {
    // grid-template-columns: var(--small-grid-template-columns);
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
    border-radius: 0 0 20px 20px;
    grid-area: menu-wrapper;
    background: #ffffff;
    position: sticky;
    top: 0;
    align-items: center;
    box-shadow: 0px 0px 20px 2px lightgray;
  }

  .active {
    visibility: visible;
    transition: all 0.5s;
    z-index: 9;
  }

  .hidden {
    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(-100%);
  }

  &__logo {
    display: flex;
    gap: 50px;
    align-items: center;
    padding-left: 70px;

    @media only screen and (max-width: 480px) {
      padding-left: 20px;
    }
  }

  &__items {
    display: inline-flex;
    gap: 40px;

    &-item {
      font-weight: 400;
      font-size: clamp(12px, 1vw, 14px);
      text-transform: uppercase;
      color: #222222;
      text-decoration: unset;
    }
  }

  &__button {
    padding-right: 70px;
    align-content: center;
    max-width: 200px;

    &-buy.MuiButtonBase-root.MuiButton-root.MuiButton-text {
      background: #f38701;
      border: 1px solid #f38701;
    }

    button {
      padding: 8px 16px;
      gap: 10px;
      width: 200px;
      height: 36px;
      border-radius: 54px;
      color: #ffffff;
      font-weight: 700;
      text-transform: unset;
      font-family: "Gilroy";
    }
  }

  .popover-wrapper {
    padding-right: 20px;
  }

  &__popover {
    width: 100%;
    position: absolute;
    left: 0;
    background: #ffffff;
    top: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 0 0 20px 20px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 20px 0 20px;
    }

    .menu__button {
      align-self: center;
      padding-right: 0;

      margin: 0 20px 10px 20px;
    }

    &-list {
      margin: 0 20px;

      .menu__items {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}
