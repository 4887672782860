.header {
  grid-area: header;
  width: 100%;
  height: inherit;

  background-image: url("../../assets/back-image.png");

  @media only screen and (max-width: 480px) {
    background-image: url("../../assets/back-image-small.png");
  }

  background-size: cover;
  height: 100%;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". header-container .";

  @media only screen and (max-width: 480px) {
    background-image: url("../../assets/back-image-small.png");
    grid-template-columns: var(--small-grid-template-columns);
  }

  .container {
    grid-area: header-container;
    gap: 50px 130px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
      margin-top: 80px;
      gap: 30px 100px;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 515px; // 130
    gap: 40px;

    @media only screen and (max-width: 480px) {
      gap: 24px;
    }
  }

  &__title {
    color: #f2ca06;
    font-weight: 700;
    font-size: clamp(60px, 5vw, 100px);
    max-width: 490px;

    @media only screen and (max-width: 480px) {
      width: 300px;
    }
  }

  &__subtitle {
    color: #ffffff;
    font-weight: 400;
    font-size: clamp(16px, 5vw, 24px);
  }

  &-info {
    display: flex;
    max-width: 450px;
    border-radius: 20px;
    background: #ffffff;
    padding: 45px 28px;
    gap: 40px;
    flex-direction: column;

    @media only screen and (max-width: 480px) {
      gap: 24px;
      padding: 24px 20px;
    }

    &__title {
      color: #596dbb;
      font-weight: 700;
      font-size: 40px;

      @media only screen and (max-width: 480px) {
        font-size: 24px;
      }
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 17px 10px;
    }

    &__buttons {
      display: flex;
      gap: 16px 28px;

      @media only screen and (max-width: 480px) {
        flex-wrap: wrap;
      }

      &-schedule.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #596dbb;
        border: 1px solid #596dbb;
      }

      &-buy.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #f38701;
        border: 1px solid #f38701;
      }
      .link {
        width: calc(100% - 17px);

        button {
          width: 100%;
          padding: 8px 16px;
          gap: 10px;
          height: 36px;
          border-radius: 54px;
          color: #ffffff;
          font-weight: 700;
          text-transform: unset;
          font-family: "Gilroy";
        }
      }
    }
  }
}
