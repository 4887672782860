.home-page {
  justify-items: center;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch
}

.details-page {
  background: #F6F7FB;
  .menu {
    position: unset;
  }
}
