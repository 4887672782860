.organizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  width: 25%;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  .MuiAvatar-root {
    border: 2px solid #596dbb;
  }

  &__info {
    text-align: center;

    &-name {
      color: #0f172a;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 8px;
      width: 200px;
    }

    &-position {
      color: #6a737c;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
