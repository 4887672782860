@mixin commonStyles {
  border-radius: 10px;
}

.schedule {
  align-items: center;
  margin-top: 80px;
  display: grid;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". schedule .";

  @media only screen and (max-width: 480px) {
    grid-template-columns: 0.5fr 11fr 0.5fr;
  }

  .first-stage {
    display: grid;
    grid-area: first-stage;
    gap: 20px;
  }

  &-title {
    color: #596dbb;
    font-weight: 700;
    font-size: 50px;
  }

  &-halls {
    display: flex;
    gap: 20px;
    margin: 16px 0;

    .halls-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 16px;
    }

    .hall-dummy {
      width: 70px;
    }

    &__hall {
      color: #f38701;
      font-weight: 700;
      font-size: 30px;
      width: clamp(302px, 20vw, 352px);
    }
  }

  .desktop-view {
    display: grid;
    grid-area: schedule;
    gap: 16px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .event {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 16px;
    font-weight: 700;
    font-size: 18px;
  }

  .time {
    @include commonStyles;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 38px;
  }

  .label {
    @include commonStyles;
    flex: 1;
    align-content: center;
    padding: 16px;
  }

  .start-color {
    color: #f38701;

    .time,
    .label {
      background: #ffe8d2;
    }
  }

  .main-color {
    color: #596dbb;

    .time,
    .label {
      background: #f6f7fb;
    }
  }

  .with-cards {
    .label {
      background: #ffffff;
    }
  }

  .reports {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }

  .mobile-view {
    display: grid;
    grid-area: schedule;
    gap: 16px;

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .schedule-title {
      margin: 0 20px;
    }

    .MuiTabs-root {
      margin: 0;

      .MuiTabs-indicator {
        background-color: #f38701;
      }

      .MuiTabs-flexContainer {
        justify-content: space-between;

        .MuiButtonBase-root {
          color: #596dbb;
          font-size: 24px;
          font-weight: 700;
          font-family: "Gilroy";
          text-transform: unset;

          &.Mui-selected {
            color: #f38701;
          }
        }
      }
    }

    .custom-tab-panel {
      .event {
        font-size: 16px;
        .time {
          padding: 8px;
          width: 44px;
          justify-content: center;
        }
        &.with-cards {
          flex-direction: column;

          .time {
            width: 100%;
            padding: 16px 0;
          }

          .report-card {
            width: 100%;
          }
        }
      }
    }
  }
}
