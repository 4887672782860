.ticket {
  justify-content: center;
  margin-top: 80px;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". ticket-wrapper .";
  display: grid;

  @media only screen and (max-width: 480px) {
    // grid-template-columns: var(--small-grid-template-columns);
  }

  &-wrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    background: #596dbb;
    border-radius: 20px;
    padding: 48px 70px;
    grid-area: ticket-wrapper;

    @media only screen and (max-width: 480px) {
      padding: 24px 20px;
      flex-wrap: wrap;
    }
  }

  &__types {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-wrapper {
      background: #f6f7fb;
      border-radius: 20px;
      padding: 20px 18px;
      display: flex;
      gap: 21px;
      justify-content: space-between;

      @media only screen and (max-width: 480px) {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &-info {
      &__title {
        color: #596dbb;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      &__subtitle {
        color: #6a737c;
        font-size: 14px;
        font-weight: 400;
        width: 250px;
      }
    }

    &-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      @media only screen and (max-width: 480px) {
        width: 100%;

        a {
          width: 100%;
        }
      }

      .buy-button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: #f38701;
        border: 1px solid #f38701;
      }

      button {
        padding: 8px 16px;
        gap: 10px;
        width: 100%;
        height: 36px;
        border-radius: 54px;
        color: #ffffff;
        font-weight: 700;
        text-transform: unset;
        min-width: 150px;
        font-family: "Gilroy";
      }

      &__prices {
        display: flex;
        flex-direction: column;
        align-items: center;

        .price {
          color: #596dbb;
          font-weight: 600;
          font-size: 16px;
        }

        .price-old {
          color: #6a737c;
          font-weight: 600;
          font-size: 12px;
          text-decoration: line-through;
          text-decoration-color: #6a737c;
        }
      }
    }
  }

  &__info {
    &-title {
      color: #ffffff;
      font-weight: 700;
      font-size: 50px;
    }

    &-list {
      color: #ffffff;
      font-weight: 400;
      font-size: 24px;

      padding-inline-start: 25px;
      margin: 0;

      li {
        padding-left: 20px;
        margin-top: 10px;

        &::marker {
          color: #f38701;
        }
      }
    }
  }
}
