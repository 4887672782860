.organizers {
  align-items: center;
  align-content: center;
  margin-top: 80px;
  grid-template-columns: var(--main-grid-template-columns);
  grid-template-areas: ". organizers-wrapper .";
  display: grid;


  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    grid-area: organizers-wrapper;
  }

  &__title {
    color: #596dbb;
    font-weight: 700;
    font-size: 50px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0;
    justify-content: left;

    @media only screen and (max-width: 480px) {
      justify-content: center;
    }
  }
}
