.tag {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #596dbb;
  border-radius: 54px;
  color: #596dbb;
  font-weight: 400;
  font-size: 22px;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
}

.report-tag {
  padding: 6px 8px;
  color: #0f172a;
  font-weight: 400;
  font-size: 16px;
  border-radius: 6px;
}
